export const background = 'var(--cmt-color-background)';
export const backgroundNavy = 'var(--cmt-color-background-navy)';
export const backgroundNavySecondary =
  'var(--cmt-color-background-navy-secondary)';
export const primaryRedLight = 'var(--cmt-color-primary-red-light)';
export const primaryRedDark = 'var(--cmt-color-primary-red-dark)';
export const white = 'var(--cmt-color-white)';
export const whiteSecondary = 'var(--cmt-color-white-secondary)';
export const whiteTertiary = 'var(--cmt-color-white-tertiary)';
export const black = 'var(--cmt-color-black)';
export const blackSecondary = 'var(--cmt-color-black-secondary)';
export const blackTertiary = 'var(--cmt-color-black-tertiary)';
export const green30 = 'var(--cmt-color-green-30)';
export const navy30 = 'var(--cmt-color-navy-30)';
export const navy35 = 'var(--cmt-color-navy-35)';
export const navy60 = 'var(--cmt-color-navy-60)';
export const navy70 = 'var(--cmt-color-navy-70)';
export const navy80 = 'var(--cmt-color-navy-80)';
export const navy90 = 'var(--cmt-color-navy-90)';
export const backgroundDefault = 'var(--cmt-color-background-default)';
export const navyOpacity95 = 'var(--cmt-color-navy-opacity-95)';
export const red50 = 'var(--cmt-color-red-50)';
export const textPrimary = 'var(--cmt-color-text-primary)';
export const textSecondary = 'var(--cmt-color-text-secondary)';
export const textTertiary = 'var(--cmt-color-text-tertiary)';
export const textDisabled = 'var(--cmt-color-text-disabled)';
export const iconTertiary = 'var(--cmt-color-icon-tertiary)';
export const iconDarkprimary = 'var(--cmt-color-icon-darkprimary)';
export const surfacePrimary = 'var(--cmt-color-surface-primary)';
export const surfaceSecondary = 'var(--cmt-color-surface-secondary)';
export const surfaceNavyOpacity95 = 'var(--cmt-color-surface-navy-opacity-95)';
export const primaryPinkDefault = 'var(--cmt-color-primary-pink-default)';
export const borderPrimary = 'var(--cmt-color-border-primary)';
export const borderTertiary = 'var(--cmt-color-border-tertiary)';
