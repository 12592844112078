import {
  navyOpacity95,
  surfacePrimary,
  textPrimary,
  textSecondary,
} from '../../App/styles/colorToken';
import styled from '@emotion/styled';

export const TableSection = styled.section`
  padding-bottom: 20px;
  margin-bottom: 10px;
  overflow-y: auto;
`;

export const Table = styled.table`
  border-collapse: collapse;
  color: ${textPrimary};
  border: 1px solid ${textSecondary};
  background: ${navyOpacity95};

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  td,
  th {
    text-align: start;
    vertical-align: text-top;
    padding: 10px;
    border: 1px solid ${textSecondary};
  }
`;

export const TableHead = styled.thead`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  background: ${surfacePrimary};
`;
