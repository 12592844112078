import styled from '@emotion/styled';
import {
  backgroundNavy,
  backgroundNavySecondary,
  blackTertiary,
  textTertiary,
  white,
  whiteSecondary,
} from '../../App/styles/colorToken';
import {
  CaptionAnchor,
  captionStyle,
  CaptionText,
  H4,
} from '../../App/components/Text';
import {
  SANTAFIVE_FACEBOOK_LINK,
  SANTAFIVE_INSTAGRAM_LINK,
  SANTAFIVE_NOTION_LINK,
  SANTAFIVE_TWITTER_LINK,
} from '../../App/constants/links';
import { ReactComponent as FacebookIcon } from '../../App/assets/icon-facebook.svg';
import { ReactComponent as TwitterIcon } from '../../App/assets/icon-twitter.svg';
import { ReactComponent as InstagramIcon } from '../../App/assets/icon-instagram.svg';
import { logClickLinkEvent, logClickSNSEvent } from '../../App/utils/analytics';

const Footer = () => {
  return (
    <Container>
      <ContentSection>
        <FooterContent>
          <SantafiveIntro>
            <SantafiveIntroTitle>산타파이브</SantafiveIntroTitle>
            <SantafiveIntroLink
              href={SANTAFIVE_NOTION_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                logClickLinkEvent({ event_label: 'click_team_introduction' })
              }
            >
              팀 소개 바로가기
            </SantafiveIntroLink>
          </SantafiveIntro>
          <CopyRightText>
            Copyright ©santafive. All rights reserved.
          </CopyRightText>
          <PrivacySection>
            <PrivacyPolicyLink href={'/privacy'}>
              개인정보 처리방침
            </PrivacyPolicyLink>
          </PrivacySection>
          <Divider />
          <SNSLinks>
            <SNSLink
              href={SANTAFIVE_INSTAGRAM_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                logClickSNSEvent({
                  event_label: 'click_sns',
                  link_sns: 'instagram',
                })
              }
            >
              <InstagramIcon />
              <CaptionText>instagram</CaptionText>
            </SNSLink>
            <SNSLink
              href={SANTAFIVE_TWITTER_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                logClickSNSEvent({
                  event_label: 'click_sns',
                  link_sns: 'twitter',
                })
              }
            >
              <TwitterIcon />
              <CaptionText>twitter</CaptionText>
            </SNSLink>
            <SNSLink
              href={SANTAFIVE_FACEBOOK_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                logClickSNSEvent({
                  event_label: 'click_sns',
                  link_sns: 'facebook',
                })
              }
            >
              <FacebookIcon />
              <CaptionText>facebook</CaptionText>
            </SNSLink>
          </SNSLinks>
        </FooterContent>
      </ContentSection>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  background-color: ${backgroundNavy};
`;

const ContentSection = styled.div`
  display: flex;
  width: 57.5rem;
`;

const FooterContent = styled.div`
  flex: 1;
  margin: 3.75rem 1.5rem;

  @media (max-width: 800px) {
    margin: 2.5rem 1.5rem;
  }
`;

const SantafiveIntro = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const SantafiveIntroTitle = styled(H4)`
  color: ${whiteSecondary};
  margin-right: 1rem;
`;

const SantafiveIntroLink = styled(CaptionAnchor)`
  display: flex;
  justify-content: center;
  color: ${white};

  background: ${backgroundNavySecondary};
  border-radius: 0.5rem;
  padding: 0.375rem 0.5rem;
`;

const CopyRightText = styled(CaptionText)`
  color: ${whiteSecondary};
`;

const PrivacySection = styled.div`
  margin: 1.5rem 0;
`;

const PrivacyPolicyLink = styled.a`
  ${captionStyle};
  color: ${textTertiary};
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${blackTertiary};
  margin: 1.5rem 0;
`;

const SNSLink = styled(CaptionAnchor)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${whiteSecondary};
  margin-right: 1.5rem;

  &:last-child {
    margin-right: 0;
  }
`;

const SNSLinks = styled.div`
  display: flex;
  flex-direction: row;
`;

export default Footer;
