import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';

const PrivacySixthSection = () => {
  return (
    <PrivacySection title={'6. 이용자 및 법정대리인의 권리 및 그 행사방법'}>
      <PrivacyParagraph>
        이용자는 언제든지 자신의 개인정보를 조회하거나 수정 또는 삭제할 수 있고,
        산타파이브에게 자신의 개인정보에 대한 열람, 정정, 처리정지, 삭제를
        요청할 수 있으며, 회원탈퇴 등을 통해 개인정보의 수집 및 이용동의를
        철회할 수 있습니다.
        <br />
        • 개인정보 변경: 계정 설정
        <br />
        {`• 동의 철회 : 계정 설정 > 회원 탈퇴`}
        <br />
        이용자가 개인정보의 오류에 대한 정정 또는 삭제를 요청한 경우,
        산타파이브는 이를 완료하기 전까지 해당 개인정보를 이용 또는 제공하지
        않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정
        처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 합니다.
        <br />
        이용자의 권리행사는 이용자의 법정대리인이나 위임받은 자 등을 통해서도
        하실 수 있고, 이 경우에는 적법한 위임장을 제출하셔야 합니다.
        <br />
      </PrivacyParagraph>
    </PrivacySection>
  );
};

export default PrivacySixthSection;
