import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';

const PrivacyTwelfthSection = () => {
  return (
    <PrivacySection title={'12. 고지의 의무'}>
      <PrivacyParagraph>
        이 개인정보 처리방침은 시행일로부터 적용됩니다. 개인정보 처리방침의
        내용은 관련법령, 정부의 정책 또는 보안기술의 변경 등에 따라 변경될 수
        있고, 그 경우 변경된 개인정보 처리방침의 시행일로부터 최소 7 일전에
        공지사항을 통해 고지합니다.
        <br />• 이 개인정보 처리방침은 2022 년 12 월 1 일부터 적용됩니다.
      </PrivacyParagraph>
    </PrivacySection>
  );
};

export default PrivacyTwelfthSection;
