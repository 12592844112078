import { useEffect } from 'react';
import styled from '@emotion/styled';
import { backgroundNavy } from '../App/styles/colorToken';
import { logPageView } from '../App/utils/analytics';
import landingBackground1xWebp from '../App/assets/landing/landing-background-1x.webp';
import landingBackground1xAsset from '../App/assets/landing/landing-background-1x.jpg';
import landingBackground2xWebp from '../App/assets/landing/landing-background-2x.webp';
import landingBackground2xAsset from '../App/assets/landing/landing-background-2x.jpg';
import NavBar from '../Landing/sections/NavBar';
import CountdownSection from '../Landing/sections/CountdownSection';
import MetricSection from '../Landing/sections/MetricSection';
import FeatureSection from '../Landing/sections/FeatureSection';
import UpdatesSection from '../Landing/sections/UpdatesSection';
import DonationSection from '../Landing/sections/DonationSection';
import AdSection from '../Landing/sections/AdSection';
import Footer from '../Landing/sections/Footer';
import Snowfall from 'react-snowfall';

const LandingPage = () => {
  useEffect(() => {
    logPageView('landing_page');
  }, []);

  return (
    <Page>
      <picture>
        <source
          type="image/webp"
          media="(max-width:800px)"
          srcSet={landingBackground1xWebp}
        />
        <source media="(max-width:800px)" srcSet={landingBackground1xAsset} />
        <source type="image/webp" srcSet={landingBackground2xWebp} />
        <BackgroundImage
          alt={'크리스마스 분위기의 마을 배경'}
          src={landingBackground2xAsset}
        />
      </picture>
      <NavBar />
      <CountdownSection />
      <MetricSection />
      <FeatureSection />
      <UpdatesSection />
      <DonationSection />
      <AdSection />
      <Footer />
      <Snowfall
        color={'rgba(255,255,255,0.8)'}
        snowflakeCount={350}
        changeFrequency={10}
        radius={[3, 7]}
        speed={[2, 5]}
        wind={[-1, 1]}
      />
    </Page>
  );
};

const Page = styled.div`
  background-color: ${backgroundNavy};
  position: relative;
  overflow-y: scroll;
`;

const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 91.625rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
  object-position: 50% 50%;
  z-index: 0;
`;

export default LandingPage;
