import styled from '@emotion/styled';
import { Body2, H4 } from '../../App/components/Text';
import {
  backgroundNavySecondary,
  white,
  whiteSecondary,
} from '../../App/styles/colorToken';
import { SANTAFIVE_AD_EMAIL } from '../../App/constants/informations';

const AdSection = () => {
  return (
    <Container id="ad-section">
      <ContentSection>
        <FooterContent>
          <SectionTitle>
            ‘내 트리를 꾸며줘’ 와 <BR />
            어울리는 광고를 달아주세요!
          </SectionTitle>
          <SectionDescription>
            광고 문의 | {SANTAFIVE_AD_EMAIL}
          </SectionDescription>
        </FooterContent>
      </ContentSection>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  background-color: ${backgroundNavySecondary};
`;

const ContentSection = styled.div`
  display: flex;
  width: 57.5rem;
`;

const FooterContent = styled.div`
  flex: 1;
  margin: 3.75rem 1.5rem;

  @media (max-width: 800px) {
    margin: 2.5rem 1.5rem;
  }
`;

const SectionTitle = styled(H4)`
  color: ${white};
  margin-bottom: 0.5rem;
`;

const SectionDescription = styled(Body2)`
  color: ${whiteSecondary};
`;

const BR = styled.br`
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;

export default AdSection;
