import { useEffect, useState } from 'react';
import FlipClockCountdown from './FlipClockCountdown';
import styled from '@emotion/styled';
import { whiteSecondary } from '../../App/styles/colorToken';
import { body2FontSizeLegacy } from '../../App/styles/typography';

const CLOCK_UNIT = {
  DAYS: 'Days',
  HOURS: 'Hours',
  MINUTES: 'Minutes',
  SECONDS: 'Seconds',
} as const;

const calcClockUnit = (width: number) => {
  if (width < 245) {
    return [CLOCK_UNIT.DAYS, CLOCK_UNIT.HOURS];
  }

  if (width < 315) {
    return [CLOCK_UNIT.DAYS, CLOCK_UNIT.HOURS, CLOCK_UNIT.MINUTES];
  }

  return [
    CLOCK_UNIT.DAYS,
    CLOCK_UNIT.HOURS,
    CLOCK_UNIT.MINUTES,
    CLOCK_UNIT.SECONDS,
  ];
};

// 어느 국가에서 접근하더라도 동일한 시간을 표기
// 2022-12-11일 00:00:00 KST
const KR_SERVICE_OPEN_TIMESTAMP = 1670684400000;

const CountdownTimer = () => {
  const [ClockUnit, setClockUnit] = useState(calcClockUnit(window.innerWidth));

  useEffect(() => {
    const onResize = () => {
      setClockUnit(calcClockUnit(window.innerWidth));
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <Countdown
      showUnits={ClockUnit}
      labels={ClockUnit}
      to={KR_SERVICE_OPEN_TIMESTAMP}
    />
  );
};

const Countdown = styled(FlipClockCountdown)`
  --fcc-digit-font-size: 2rem;
  --fcc-digit-block-width: 2.375rem;
  --fcc-digit-block-height: 3.125rem;
  --fcc-label-color: ${whiteSecondary};
  --fcc-divider-color: rgba(255, 255, 255, 0.2);
  --fcc-label-font-size: ${body2FontSizeLegacy};

  @media (max-width: 800px) {
    justify-content: center;
    --fcc-digit-block-width: 1.75rem;
  }
`;

export default CountdownTimer;
