import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/css';
import { backgroundNavy, white } from '../../App/styles/colorToken';
import { ReactComponent as LogoText } from '../../App/assets/logo-text.svg';
import { logClickNavigateEvent } from '../../App/utils/analytics';

const NavBar = () => {
  const [scrollPos, setScrollPos] = useState(0);

  const animation = scrollPos === 0 ? 'fadeOut' : 'fadeIn';

  const FeatureSection = document.getElementById('feature-section');
  const DonationSection = document.getElementById('donation-section');
  const AdSection = document.getElementById('ad-section');

  const handleScroll = (
    section: 'home' | 'feature-section' | 'donation-section' | 'ad-section'
  ) => {
    switch (section) {
      case 'home':
        logClickNavigateEvent({
          event_label: 'click_navigation_button',
          navigate_to: 'home',
        });
        return window.scrollTo({ top: 0, behavior: 'smooth' });
      case 'feature-section':
        logClickNavigateEvent({
          event_label: 'click_navigation_button',
          navigate_to: 'feature',
        });
        return window.scrollTo({
          top: FeatureSection?.offsetTop,
          behavior: 'smooth',
        });
      case 'donation-section':
        logClickNavigateEvent({
          event_label: 'click_navigation_button',
          navigate_to: 'donation',
        });
        return window.scrollTo({
          top: DonationSection?.offsetTop,
          behavior: 'smooth',
        });
      case 'ad-section':
        logClickNavigateEvent({
          event_label: 'click_navigation_button',
          navigate_to: 'ad',
        });
        return window.scrollTo({
          top: AdSection?.offsetTop,
          behavior: 'smooth',
        });
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollPos(window.scrollY);
    });
  }, []);

  return (
    <Container animation={animation}>
      <InnerContainer>
        <LogoText />
        <Navigation>
          <NavigationList>
            <NavigationItem>
              <button onClick={() => handleScroll('home')}>홈</button>
            </NavigationItem>
            <NavigationItem>
              <button onClick={() => handleScroll('feature-section')}>
                소개
              </button>
            </NavigationItem>
            <NavigationItem>
              <button onClick={() => handleScroll('donation-section')}>
                기부소식
              </button>
            </NavigationItem>
            <NavigationItem>
              <button onClick={() => handleScroll('ad-section')}>
                광고문의
              </button>
            </NavigationItem>
          </NavigationList>
        </Navigation>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.nav<{ animation: 'fadeIn' | 'fadeOut' }>`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 2;
  background-color: ${backgroundNavy};

  @media (min-width: 801px) {
    animation: ${({ animation }) => (animation === 'fadeIn' ? fadeIn : fadeOut)}
      0.5s ease forwards;
  }
`;

const fadeIn = keyframes`
  from {
    background-color: rgba(17, 21, 28, 0);
  }
  to {
    background-color: rgba(17, 21, 28, 1);
  }
`;

const fadeOut = keyframes`
  from {
    background-color: rgba(17, 21, 28, 1);
  }
  to {
    background-color: rgba(17, 21, 28, 0);
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 57.5rem;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    justify-content: flex-start;
  }
`;

const Navigation = styled.nav`
  @media (max-width: 800px) {
    display: none;
  }
`;

const NavigationList = styled.ol`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;

const NavigationItem = styled.li`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${white};
`;

export default NavBar;
