import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';

const PrivacyEighthSection = () => {
  return (
    <PrivacySection title={'8. 개인정보 수집 동의 거부'}>
      <PrivacyParagraph>
        회원은 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의를
        거부할 경우 받는 별도의 불이익은 없습니다.
        <br />
        단, 필수 동의 사항에 동의를 거부할 경우, 서비스 이용이 불가능하거나
        서비스 이용 목적에 따른 서비스 제공에 제한이 따르게 됩니다.
      </PrivacyParagraph>
    </PrivacySection>
  );
};

export default PrivacyEighthSection;
