import { Table, TableHead, TableSection } from '../PrivacyTable';
import styled from '@emotion/styled';

const PrivacyCollectionTable = () => {
  return (
    <TableSection>
      <FixedTable>
        <TableHead>
          <tr>
            <th style={{ width: '9.125rem' }}>가입경로</th>
            <th style={{ width: '9.125rem' }}>필수 항목</th>
            <th style={{ width: '9.125rem' }}>선택 항목</th>
            <th style={{ width: '19.3125rem' }}>보유 및 이용기간</th>
          </tr>
        </TableHead>
        <tbody>
          <tr>
            <td>카카오 계정을 연동한 회원가입</td>
            <td>이용자 고유 식별자, 닉네임</td>
            <td>이메일 주소</td>
            <td>
              회원탈퇴시 까지
              <br />※ 단, 관계 법령 위반에 따른 수사, 조사 등이 진행중인
              경우에는 해당 수사, 조사 종료 시 까지 보관 하며 내부규정 혹은
              관련법령에 따라 일정기간 보관됨.
            </td>
          </tr>
          <tr>
            <td>Twitter 계정을 연동한 회원가입</td>
            <td>이용자 고유 식별자, 트윗 및 프로필 정보, 닉네임</td>
            <td>이메일 주소</td>
            <td>
              회원탈퇴시 까지
              <br />※ 단, 관계 법령 위반에 따른 수사, 조사 등이 진행중인
              경우에는 해당 수사, 조사 종료 시 까지 보관 하며 내부규정 혹은
              관련법령에 따라 일정기간 보관됨.
            </td>
          </tr>
          <tr>
            <td>Google 계정을 연동한 회원가입</td>
            <td>이용자 고유 식별자, 이메일 주소, 닉네임</td>
            <td>이메일 주소</td>
            <td>
              회원탈퇴시 까지
              <br />※ 단, 관계 법령 위반에 따른 수사, 조사 등이 진행중인
              경우에는 해당 수사, 조사 종료 시 까지 보관 하며 내부규정 혹은
              관련법령에 따라 일정기간 보관됨.
            </td>
          </tr>
        </tbody>
      </FixedTable>
    </TableSection>
  );
};

const FixedTable = styled(Table)`
  width: 46.75rem;
  table-layout: fixed;
`;

export default PrivacyCollectionTable;
