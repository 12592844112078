import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  body1FontSize,
  body1FontSizeLegacy,
  body1FontWeight,
  body1LineHeight,
  body1LineHeightLegacy,
  body2FontSize,
  body2FontSizeLegacy,
  body2FontWeight,
  body2LineHeight,
  body2LineHeightLegacy,
  bodyStrongFontSize,
  bodyStrongFontWeight,
  bodyStrongLineHeight,
  buttonFontSize,
  buttonFontSizeLegacy,
  buttonFontWeight,
  buttonLineHeight,
  buttonLineHeightLegacy,
  captionFontSize,
  captionFontSizeLegacy,
  captionFontWeight,
  captionLineHeight,
  captionLineHeightLegacy,
  h1FontSize,
  h1FontSizeLegacy,
  h1FontWeight,
  h1LineHeight,
  h1LineHeightLegacy,
  h2FontSize,
  h2FontSizeLegacy,
  h2FontWeight,
  h2LineHeight,
  h2LineHeightLegacy,
  h3FontSize,
  h3FontSizeLegacy,
  h3FontWeight,
  h3LineHeight,
  h3LineHeightLegacy,
  h4FontSizeLegacy,
  h4LineHeightLegacy,
} from '../styles/typography';

export const h1Style = css`
  font-size: ${h1FontSize};
  line-height: ${h1LineHeight};
  font-weight: ${h1FontWeight};
`;

export const h2Style = css`
  font-size: ${h2FontSize};
  line-height: ${h2LineHeight};
  font-weight: ${h2FontWeight};
`;

export const h3Style = css`
  font-size: ${h3FontSize};
  line-height: ${h3LineHeight};
  font-weight: ${h3FontWeight};
`;

export const body1Style = css`
  font-size: ${body1FontSize};
  line-height: ${body1LineHeight};
  font-weight: ${body1FontWeight};
`;

export const body2Style = css`
  font-size: ${body2FontSize};
  line-height: ${body2LineHeight};
  font-weight: ${body2FontWeight};
`;

export const bodyStrongStyle = css`
  font-size: ${bodyStrongFontSize};
  line-height: ${bodyStrongLineHeight};
  font-weight: ${bodyStrongFontWeight};
`;

export const buttonStyle = css`
  font-size: ${buttonFontSize};
  line-height: ${buttonLineHeight};
  font-weight: ${buttonFontWeight};
`;

export const captionStyle = css`
  font-size: ${captionFontSize};
  line-height: ${captionLineHeight};
  font-weight: ${captionFontWeight};
`;

/* 하위는 레거시 */
export const H1 = styled.h1`
  font-size: ${h1FontSizeLegacy};
  font-weight: 700;
  line-height: ${h1LineHeightLegacy};
`;

export const H2 = styled.h1`
  font-size: ${h2FontSizeLegacy};
  font-weight: 700;
  line-height: ${h2LineHeightLegacy};
`;

export const H3 = styled.h3`
  font-size: ${h3FontSizeLegacy};
  font-weight: 700;
  line-height: ${h3LineHeightLegacy};
`;

export const H4 = styled.h4`
  font-size: ${h4FontSizeLegacy};
  font-weight: 700;
  line-height: ${h4LineHeightLegacy};
`;

export const Body1 = styled.span`
  font-size: ${body1FontSizeLegacy};
  font-weight: 500;
  line-height: ${body1LineHeightLegacy};
`;

export const Body2 = styled.span`
  font-size: ${body2FontSizeLegacy};
  font-weight: 500;
  line-height: ${body2LineHeightLegacy};
`;

export const CaptionAnchor = styled.a`
  font-size: ${captionFontSizeLegacy};
  font-weight: 500;
  line-height: ${captionLineHeightLegacy};
`;

export const CaptionText = styled.span`
  font-size: ${captionFontSizeLegacy};
  font-weight: 500;
  line-height: ${captionLineHeightLegacy};
`;

export const ButtonText = styled.p`
  font-size: ${buttonFontSizeLegacy};
  font-weight: 700;
  line-height: ${buttonLineHeightLegacy};
`;
