import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';
import PrivacyCollectionTable from '../tables/PrivacyCollectionTable';

const PrivacyFirstSection = () => {
  return (
    <PrivacySection title={'1. 개인정보 수집 및 이용 현황'}>
      <PrivacyParagraph>
        회원가입 시점에 산타파이브가 이용자로부터 수집하는 개인정보는 아래와
        같습니다.
      </PrivacyParagraph>
      <PrivacyCollectionTable />
      <PrivacyParagraph>
        또한, 이용자의 서비스 이용과정에서 서비스 이용 기록(접속일시,
        이용과정에서 발생하는 정상 또는 비정상 로그 등), IP 주소, 기기정보,
        광고식별자 등의 정보가 생성되어 수집될 수 있습니다.
      </PrivacyParagraph>
      <PrivacyParagraph>
        산타파이브는 아래의 방법을 통해 이용자의 개인정보를 수집합니다.
        <br />
        • 회원가입 및 서비스 이용시 이용자가 직접 정보를 입력하는 경우, 해당
        개인정보를 수집합니다.
        <br />
        • 이용자의 문의 및 응대시 웹페이지, 메일, 전화 등을 통해 이용자의
        개인정보가 수집될 수 있습니다.
        <br />• 서비스 이용 정보 및 생성정보 등은 서비스 이용 과정에서 자동으로
        생성되어 수집될 수 있습니다.
      </PrivacyParagraph>
    </PrivacySection>
  );
};

export default PrivacyFirstSection;
