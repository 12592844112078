import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';
import PrivacyTransferTable from '../tables/PrivacyTransferTable';

const PrivacyFourthSection = () => {
  return (
    <PrivacySection title={'4. 개인정보의 국외 이전에 관한 사항'}>
      <PrivacyParagraph>
        산타파이브 데이터 분석과 데이터 분산 저장을 위해서 이용자의 개인정보를
        아래와 같이 해외 서비스에 위탁하고 있습니다.
        <br />
      </PrivacyParagraph>
      <PrivacyTransferTable />
    </PrivacySection>
  );
};

export default PrivacyFourthSection;
