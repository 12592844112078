import { ReactNode } from 'react';
import { textPrimary } from '../../App/styles/colorToken';
import styled from '@emotion/styled';

interface PrivacySectionProps {
  title: string;
  children?: ReactNode;
}

const PrivacySection = (props: PrivacySectionProps) => {
  return (
    <Section>
      <Title>{props.title}</Title>
      {props.children}
    </Section>
  );
};

const Section = styled.section`
  margin-bottom: 1.4375rem;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 2rem;

  color: ${textPrimary};
  margin-bottom: 0.625rem;
`;

export default PrivacySection;
