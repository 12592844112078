import { Table, TableHead, TableSection } from '../PrivacyTable';
import styled from '@emotion/styled';

const CounselingTable = () => {
  return (
    <TableSection>
      <FixedTable>
        <TableHead>
          <tr>
            <th style={{ width: '11.875rem' }}>문의처</th>
            <th style={{ width: '11.875rem' }}>연락처</th>
            <th style={{ width: '11.875rem' }}>홈페이지</th>
          </tr>
        </TableHead>
        <tbody>
          <tr>
            <td>개인정보침해 신고센터</td>
            <td>(국번없이) 118</td>
            <td>https://privacy.kisa.or.kr/</td>
          </tr>
          <tr>
            <td>개인정보 분쟁조정위원회</td>
            <td>(국번없이) 1833-6972</td>
            <td>https://www.kopico.go.kr/</td>
          </tr>
          <tr>
            <td>대검찰청 사이버범죄수사단</td>
            <td>(국번없이) 1301</td>
            <td>https://www.spo.go.kr/</td>
          </tr>
          <tr>
            <td>경찰청 사이버안전지킴이</td>
            <td>(국번없이) 182</td>
            <td>https://www.police.go.kr/</td>
          </tr>
        </tbody>
      </FixedTable>
    </TableSection>
  );
};

const FixedTable = styled(Table)`
  width: 35.625rem;
  table-layout: fixed;

  td {
    height: 3.5rem;
  }
`;

export default CounselingTable;
