import styled from '@emotion/styled';
import { Body1, H2 } from '../../App/components/Text';
import { white, whiteSecondary } from '../../App/styles/colorToken';

import treeFeature2xWebp from '../../App/assets/landing/image-tree-feature-2x.webp';
import treeFeature2xAsset from '../../App/assets/landing/image-tree-feature-2x.png';
import treeFeatureMobile2xWebp from '../../App/assets/landing/image-tree-feature-mobile-2x.webp';
import treeFeatureMobile2xAsset from '../../App/assets/landing/image-tree-feature-mobile-2x.png';

import messageFeature2xWebp from '../../App/assets/landing/image-message-feature-2x.webp';
import messageFeature2xAsset from '../../App/assets/landing/image-message-feature-2x.png';
import messageFeatureMobile2xWebp from '../../App/assets/landing/image-message-feature-mobile-2x.webp';
import messageFeatureMobile2xAsset from '../../App/assets/landing/image-message-feature-mobile-2x.png';

import message2xWebp from '../../App/assets/landing/image-message-2x.webp';
import message2xAsset from '../../App/assets/landing/image-message-2x.png';

const FeatureSection = () => {
  return (
    <Container id={'feature-section'}>
      <InnerContainer>
        <TreeFeatureSection>
          <FeatureContent>
            <FeatureTitle role="text" id="tree-feature">
              나만의 트리를 만들고
              <br />
              링크를 공유해 보아요
            </FeatureTitle>
            <FeatureDescription role="text" aria-details="tree-feature">
              트리를 꾸며주길 바라는 사람들에게
              <br />
              링크를 공유해서 장식과 메시지를 부탁해 보세요
            </FeatureDescription>
          </FeatureContent>
          <WebPicture>
            <source type="image/webp" srcSet={treeFeature2xWebp} />
            <TreeFeatureWebImage
              alt={''}
              loading="lazy"
              src={treeFeature2xAsset}
            />
          </WebPicture>
          <MobilePicture>
            <source type="image/webp" srcSet={treeFeatureMobile2xWebp} />
            <FeatureMobileImage
              alt={''}
              loading="lazy"
              src={treeFeatureMobile2xAsset}
            />
          </MobilePicture>
        </TreeFeatureSection>
        <MessageFeatureSection>
          <FeatureContent>
            <FeatureTitle role="text" id="message-feature">
              친구들이 장식을 달아주면
              <br />
              하나뿐인 트리가 완성돼요
            </FeatureTitle>
            <FeatureDescription role="text" aria-details="message-feature">
              트리 장식과 함께 달린 메시지는
              <br />
              크리스마스 당일에 읽을 수 있어요
            </FeatureDescription>
          </FeatureContent>
          <WebPicture>
            <source type="image/webp" srcSet={messageFeature2xWebp} />
            <MessageFeatureWebImage
              alt={''}
              loading="lazy"
              src={messageFeature2xAsset}
            />
          </WebPicture>
          <MobilePicture>
            <source type="image/webp" srcSet={messageFeatureMobile2xWebp} />
            <FeatureMobileImage
              alt={''}
              loading="lazy"
              src={messageFeatureMobile2xAsset}
            />
          </MobilePicture>
          <MessagePicture>
            <source type="image/webp" srcSet={message2xWebp} />
            <MessageImage alt={''} loading="lazy" src={message2xAsset} />
          </MessagePicture>
        </MessageFeatureSection>
      </InnerContainer>
    </Container>
  );
};

export default FeatureSection;

const WebPicture = styled.picture`
  display: block;

  @media (max-width: 800px) {
    display: none;
  }
`;

const MobilePicture = styled.picture`
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;

const Container = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.75rem 0 6rem;
  z-index: 1;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 57.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TreeFeatureSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
  }
`;

const MessageFeatureSection = styled.div`
  width: 100%;
  margin-top: -18rem;
  margin-right: -3rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 800px) {
    margin-top: 4rem;
    margin-right: 0rem;
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
  }
`;

const FeatureContent = styled.div`
  padding: 6.5rem 0 15rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: 800px) {
    text-align: center;
    padding: 0;
    gap: 1rem;
  }
`;

const FeatureTitle = styled(H2)`
  color: ${white};
`;

const FeatureDescription = styled(Body1)`
  color: ${whiteSecondary};
`;

const TreeFeatureWebImage = styled.img`
  width: 26rem;
  margin-right: 2.6rem;

  @media (max-width: 800px) {
    display: none;
  }
`;

const MessageFeatureWebImage = styled.img`
  width: 26rem;

  @media (max-width: 800px) {
    display: none;
  }
`;

const FeatureMobileImage = styled.img`
  width: 12.5rem;
  margin-bottom: 1rem;
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;

const MessagePicture = styled.picture`
  position: absolute;
  left: calc(50% - 7rem);
  bottom: -2rem;

  @media (max-width: 800px) {
    top: 10.25rem;
    left: calc(50% - 11rem);
  }
`;

const MessageImage = styled.img`
  width: 25rem;

  @media (max-width: 800px) {
    width: 14rem;
  }
`;
