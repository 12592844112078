export const h1FontSize = 'var(--cmt-typography-h1-font-size)';
export const h1LineHeight = 'var(--cmt-typography-h1-line-height)';
export const h1FontWeight = 'var(--cmt-typography-h1-font-weight)';

export const h2FontSize = 'var(--cmt-typography-h2-font-size)';
export const h2LineHeight = 'var(--cmt-typography-h2-line-height)';
export const h2FontWeight = 'var(--cmt-typography-h2-font-weight)';

export const h3FontSize = 'var(--cmt-typography-h3-font-size)';
export const h3LineHeight = 'var(--cmt-typography-h3-line-height)';
export const h3FontWeight = 'var(--cmt-typography-h3-font-weight)';

export const body1FontSize = 'var(--cmt-typography-body1-font-size)';
export const body1LineHeight = 'var(--cmt-typography-body1-line-height)';
export const body1FontWeight = 'var(--cmt-typography-body1-font-weight)';

export const body2FontSize = 'var(--cmt-typography-body2-font-size)';
export const body2LineHeight = 'var(--cmt-typography-body2-line-height)';
export const body2FontWeight = 'var(--cmt-typography-body2-font-weight)';

export const bodyStrongFontSize = 'var(--cmt-typography-body-strong-font-size)';
export const bodyStrongLineHeight =
  'var(--cmt-typography-body-strong-line-height)';
export const bodyStrongFontWeight =
  'var(--cmt-typography-body-strong-font-weight)';

export const buttonFontSize = 'var(--cmt-typography-button-font-size)';
export const buttonLineHeight = 'var(--cmt-typography-button-line-height)';
export const buttonFontWeight = 'var(--cmt-typography-button-font-weight)';

export const captionFontSize = 'var(--cmt-typography-caption-font-size)';
export const captionLineHeight = 'var(--cmt-typography-caption-line-height)';
export const captionFontWeight = 'var(--cmt-typography-caption-font-weight)';

// 하위는 레거시로 이후 삭제
export const h1FontSizeLegacy = 'var(--cmt-legacy-typography-h1-font-size)';
export const h2FontSizeLegacy = 'var(--cmt-legacy-typography-h2-font-size)';
export const h3FontSizeLegacy = 'var(--cmt-legacy-typography-h3-font-size)';
export const h4FontSizeLegacy = 'var(--cmt-legacy-typography-h4-font-size)';
export const body1FontSizeLegacy =
  'var(--cmt-legacy-typography-body1-font-size)';
export const body2FontSizeLegacy =
  'var(--cmt-legacy-typography-body2-font-size)';
export const captionFontSizeLegacy =
  'var(--cmt-legacy-typography-caption-font-size)';
export const buttonFontSizeLegacy =
  'var(--cmt-legacy-typography-button-font-size)';
export const h1LineHeightLegacy = 'var(--cmt-legacy-typography-h1-line-height)';
export const h2LineHeightLegacy = 'var(--cmt-legacy-typography-h2-line-height)';
export const h3LineHeightLegacy = 'var(--cmt-legacy-typography-h3-line-height)';
export const h4LineHeightLegacy = 'var(--cmt-legacy-typography-h4-line-height)';
export const body1LineHeightLegacy =
  'var(--cmt-legacy-typography-body1-line-height)';
export const body2LineHeightLegacy =
  'var(--cmt-legacy-typography-body2-line-height)';
export const captionLineHeightLegacy =
  'var(--cmt-legacy-typography-caption-line-height)';
export const buttonLineHeightLegacy =
  'var(--cmt-legacy-typography-button-line-height)';
