import styled from '@emotion/styled';
import {
  primaryRedDark,
  white,
  whiteSecondary,
} from '../../App/styles/colorToken';
import { Body1, H1 } from '../../App/components/Text';
import CountdownTimer from '../countdown/CountdownTimer';
import TreeNone1xWebp from '../../App/assets/tree-1x.webp';
import TreeNone2xWebp from '../../App/assets/tree-2x.webp';
import TreeNone1xAsset from '../../App/assets/tree-1x.png';
import TreeNone2xAsset from '../../App/assets/tree-2x.png';

const CountdownSection = () => {
  return (
    <Main>
      <MainContent>
        <MainLeft>
          <Title role={'text'}>
            모두를 위한
            <br />
            크리스마스 롤링페이퍼
            <br />
            <Highlight>내 트리를 꾸며줘!</Highlight>
          </Title>
          <TreeCounterText>12월 11일에 만나요!</TreeCounterText>
          <CountdownTimer />
        </MainLeft>
        <MainRight>
          <picture>
            <source
              type="image/webp"
              media="(max-width:800px)"
              srcSet={TreeNone1xWebp}
            />
            <source type="image/webp" srcSet={TreeNone2xWebp} />
            <source media="(max-width:800px)" srcSet={TreeNone1xAsset} />
            <TreeImage alt={'크리스마스 트리'} src={TreeNone2xAsset} />
          </picture>
        </MainRight>
      </MainContent>
    </Main>
  );
};

const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6.125rem 0 0;
`;

const MainContent = styled.section`
  display: flex;
  width: 57.5rem;
  margin: 0 1.5rem 4rem;
  align-items: center;
  z-index: 1;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

const MainLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 2.578125rem 2.5rem 2.578125rem 0.695625rem;

  @media (max-width: 800px) {
    margin: 0;
  }
`;

const Title = styled(H1)`
  color: ${white};
  margin-bottom: 2rem;

  @media (max-width: 800px) {
    margin: 1.5rem 0;
    text-align: center;
  }
`;

const TreeCounterText = styled(Body1)`
  color: ${whiteSecondary};
  margin-bottom: 0.625rem;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const Highlight = styled.mark`
  color: ${primaryRedDark};
`;

const MainRight = styled.div`
  margin-right: 0.695625rem;
`;

const TreeImage = styled.img`
  width: 18rem;

  @media (max-width: 800px) {
    width: 10rem;
  }
`;

export default CountdownSection;
