import styled from '@emotion/styled';
import { ToastContainer, toast } from 'react-toastify';
import { navyOpacity95, textPrimary } from '../styles/colorToken';
import { body2Style } from './Text';

export const showToast = (message: string) => {
  return toast(message);
};

const Toast = () => {
  return (
    <Container position="bottom-center" hideProgressBar autoClose={3000} />
  );
};

const Container = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: auto;
    padding: 0;
    bottom: 1rem;
  }

  .Toastify__toast {
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin: 0;
    border-radius: 1rem;
    min-height: 0;
    background-color: ${navyOpacity95};
  }
  .Toastify__toast-body {
    flex: 0 1 auto;
    padding: 0;
    ${body2Style};
    color: ${textPrimary};
  }

  button[aria-label='close'] {
    display: none;
  }
`;

export default Toast;
