export function logPageView(pageName: string) {
  gtag('event', 'page_view', { page_name: pageName });
}

export function logClickNavigateEvent(eventProperty: {
  event_label: 'click_navigation_button';
  navigate_to: 'home' | 'feature' | 'donation' | 'ad';
}) {
  gtag('event', 'click', { ...eventProperty });
}

export function logClickLinkEvent(eventProperty: {
  event_label: 'click_team_introduction' | 'click_donation_document';
}) {
  gtag('event', 'click', { ...eventProperty });
}

export function logClickSNSEvent(eventProperty: {
  event_label: 'click_sns';
  link_sns: 'facebook' | 'instagram' | 'twitter';
}) {
  gtag('event', 'click', { ...eventProperty });
}
