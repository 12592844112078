import styled from '@emotion/styled';
import { ReactComponent as BackIcon } from '../Privacy/assets/arrow-back-icon.svg';
import { textTertiary } from '../App/styles/colorToken';
import { body1Style } from '../App/components/Text';
import ColorMyTreePrivacySection from '../Privacy/components/sections/ColorMyTreePrivacySection';
import PrivacyFirstSection from '../Privacy/components/sections/PrivacyFirstSection';
import PrivacySecondSection from '../Privacy/components/sections/PrivacySecondSection';
import PrivacyThirdSection from '../Privacy/components/sections/PrivacyThirdSection';
import PrivacyFourthSection from '../Privacy/components/sections/PrivacyFourthSection';
import PrivacyFifthSection from '../Privacy/components/sections/PrivacyFifthSection';
import PrivacySixthSection from '../Privacy/components/sections/PrivacySixthSection';
import PrivacySeventhSection from '../Privacy/components/sections/PrivacySeventhSection';
import PrivacyEighthSection from '../Privacy/components/sections/PrivacyEighthSection';
import PrivacyNinthSection from '../Privacy/components/sections/PrivacyNinthSection';
import PrivacyTenthSection from '../Privacy/components/sections/PrivacyTenthSection';
import PrivacyEleventhSection from '../Privacy/components/sections/PrivacyEleventhSection';
import PrivacyTwelfthSection from '../Privacy/components/sections/PrivacyTwelfthSection';
import { useNavigate } from 'react-router-dom';

const PrivacyPage = () => {
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate('/', {
      replace: true,
    });
  };

  return (
    <Container>
      <Header>
        <BackButton onClick={handleBackButton}>
          <BackIcon />
          메인으로
        </BackButton>
        <PageTitle>개인정보 처리방침</PageTitle>
      </Header>
      <Contents>
        <ColorMyTreePrivacySection />
        <PrivacyFirstSection />
        <PrivacySecondSection />
        <PrivacyThirdSection />
        <PrivacyFourthSection />
        <PrivacyFifthSection />
        <PrivacySixthSection />
        <PrivacySeventhSection />
        <PrivacyEighthSection />
        <PrivacyNinthSection />
        <PrivacyTenthSection />
        <PrivacyEleventhSection />
        <PrivacyTwelfthSection />
      </Contents>
    </Container>
  );
};

const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60.5rem;
  margin: 0 auto;
`;

const Header = styled.header`
  display: flex;
  margin: 0.875rem 1.5rem;
`;

const Contents = styled.article`
  margin: 1rem 1.5rem;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;

  ${body1Style};
  color: ${textTertiary};
`;

const PageTitle = styled.h1`
  display: none;
`;

export default PrivacyPage;
