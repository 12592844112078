import styled from '@emotion/styled';
import { Body2, H3 } from '../../App/components/Text';
import { background, blackSecondary } from '../../App/styles/colorToken';
import ImageMessageAsset from '../../App/assets/image-message.png';
import ImageTreeAsset from '../../App/assets/image-tree.png';
import ImageBearAsset from '../../App/assets/image-bear.png';
import RollingNumber from '../components/RollingNumber';

const MetricSection = () => {
  const renderRollingNumber = (text: string) => {
    return text.split('').map((char, idx) => {
      if (isNaN(Number(char))) return char;
      return <RollingNumber char={char} key={`${idx}_${char}`} />;
    });
  };

  return (
    <Container>
      <InnerContainer>
        <ContentWrapper role="text" aria-label="주고받은 메시지 36,973,307개">
          <ContentImage alt={''} loading="lazy" src={ImageMessageAsset} />
          <ContentTitle aria-hidden="true">
            {renderRollingNumber('36,973,307개')}
          </ContentTitle>
          <ContentDescription aria-hidden="true">
            주고받은 메시지
          </ContentDescription>
        </ContentWrapper>
        <ContentWrapper role="text" aria-label="총 사용자 2,522,744명">
          <ContentImage alt={''} loading="lazy" src={ImageTreeAsset} />
          <ContentTitle aria-hidden="true">
            {renderRollingNumber('2,522,744명')}
          </ContentTitle>
          <ContentDescription aria-hidden="true">총 사용자</ContentDescription>
        </ContentWrapper>
        <ContentWrapper role="text" aria-label="최대 동시접속자 1,170,000명">
          <ContentImage alt={''} loading="lazy" src={ImageBearAsset} />
          <ContentTitle aria-hidden="true">
            {renderRollingNumber('1,170,000명')}
          </ContentTitle>
          <ContentDescription aria-hidden="true">
            최대 동시접속자
          </ContentDescription>
        </ContentWrapper>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.75rem 0;
  z-index: 1;
  background-color: ${background};
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 57.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 2.5rem;
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentImage = styled.img`
  margin-bottom: 1.5rem;
`;

const ContentTitle = styled(H3)`
  margin-bottom: 0.5rem;
  line-height: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContentDescription = styled(Body2)`
  color: ${blackSecondary};
`;

export default MetricSection;
