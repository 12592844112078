import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';

const PrivacySecondSection = () => {
  return (
    <PrivacySection title={'2. 개인정보 수집 및 이용목적'}>
      <PrivacyParagraph>
        산타파이브는 아래의 목적을 위해 이용자의 개인정보를 수집 및 활용합니다.
        <br />
        • 회원제 서비스 이용에 따른 회원가입 의사의 확인, 회원탈퇴 의사의 확인
        등 회원관리
        <br />
        • 관련법령, 이용약관 등 위반행위에 대한 제재, 부정 이용 행위를 포함하여
        서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 약관 개정
        등의 고지사항 전달, 질의 및 민원처리 등 이용자 보호 및 서비스 운영
        <br />
        • 서비스의 원활한 제공 및 개선, 신규 서비스 개발, 서비스 이용기록과
        접속빈도 등의 분석 및 관심사, 기호 등에 기반한 맞춤형 서비스 제공
        <br />• 이용자가 안심하고 이용할 수 있는 안전한 서비스 이용환경 구축
      </PrivacyParagraph>
    </PrivacySection>
  );
};

export default PrivacySecondSection;
