import { Table, TableHead, TableSection } from '../PrivacyTable';
import styled from '@emotion/styled';

const PrivacyTransferTable = () => {
  return (
    <TableSection>
      <FixedTable>
        <TableHead>
          <tr>
            <th style={{ width: '9.080625rem' }}>회사명</th>
            <th style={{ width: '9.080625rem' }}>이전 목적</th>
            <th style={{ width: '9.080625rem' }}>연락처</th>
            <th style={{ width: '9.080625rem' }}>개인정보 이전국가</th>
            <th style={{ width: '9.080625rem' }}>이전되는 항목</th>
            <th style={{ width: '9.080625rem' }}>이전 일시 및 방법</th>
            <th style={{ width: '9.080625rem' }}>보유 및 이용 기간</th>
          </tr>
        </TableHead>
        <tbody>
          <tr>
            <td>Google Cloud Platform</td>
            <td>
              Google Cloud Storage에 데이터 저장 및 Google BigQuery를 통한
              데이터 분석
            </td>
            <td>080-822-1422</td>
            <td>미국</td>
            <td>수집하는 모든 개인정보</td>
            <td>
              데이터 수집 후 수분 이내 Google 클라우드 컴퓨팅 환경에 개인정보
              보관
            </td>
            <td>회원탈퇴 또는 위탁계약 종료시</td>
          </tr>
          <tr>
            <td>Amazon Web Services, Inc</td>
            <td>Simple Storage Service를 이용한 데이터 저장</td>
            <td>82)02-1544-8667</td>
            <td>미국(Amazon Oregon Region)</td>
            <td>수집하는 모든 개인정보</td>
            <td>
              데이터 수집 후 수분 이내 Amazon 클라우드 컴퓨팅 환경에 개인정보
              보관
            </td>
            <td>회원탈퇴 또는 위탁계약 종료시</td>
          </tr>
        </tbody>
      </FixedTable>
    </TableSection>
  );
};

const FixedTable = styled(Table)`
  width: 63.5625rem;
  table-layout: fixed;

  td {
    height: 7.875rem;
  }
`;

export default PrivacyTransferTable;
