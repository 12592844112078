import styled from "@emotion/styled";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";

type RollingNumberProps = {
  char: string;
}

const RollingNumber: React.FC<RollingNumberProps> = ({ char }) => {
  const numberArr = Math.floor(Math.random() * 10 ** 10).toString().padStart(10, '0') + char;

  const ref = useRef(null);
  const isInView = useInView(ref, { margin: "0px 0px -20px 0px", once: true });

  return (
    <View ref={ref} isBefore={!isInView}>
      <motion.div transition={{ ease: 'easeOut', duration: Math.random() + 0.5 }} initial={{ y: 0 }}
        animate={isInView ? { y: `-10em` } : { y: 0 }}
      >
        <Wrapper>
          {numberArr}
        </Wrapper>
      </motion.div>
    </View>
  )
};

const View = styled.div<{ isBefore: boolean }>`
  width: 0.95em;
  height: 1em;
  overflow: hidden;
  margin: 0 -0.18em;
`;

const Wrapper = styled.p`
  width: 1em;
  word-wrap: break-word;
  line-height: 1em;
  vertical-align: middle;
  padding-left: 0.18em;
`;

export default RollingNumber;