// import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './_pages/LandingPage';
// import HomePage from './_pages/HomePage';
// import AdWrapper from './Ad/WithAd';
// import SettingsPage from './_pages/SettingsPage';
// import AccountSettingsPage from './_pages/AccountSettingsPage';
import Toast from './App/components/Toast';
import PrivacyPage from './_pages/PrivacyPage';

// const HomeComponent = () => {
//   const [isMenuOpen, setMenuOpen] = useState(false);
//
//   const handleMenuClick = () => {
//     setMenuOpen(true);
//   };
//
//   const handleMenuClose = () => {
//     setMenuOpen(false);
//   };
//
//   return (
//     <>
//       <AdWrapper>
//         <HomePage onMenuClick={handleMenuClick} />
//       </AdWrapper>
//       <SettingsPage isMenuOpen={isMenuOpen} onCloseMenu={handleMenuClose} />
//     </>
//   );
// };

function App() {
  return (
    <>
      <Routes>
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="*" element={<LandingPage />} />
        {/*<Route path="/home" element={<HomeComponent />} />*/}
        {/*<Route path="/account-settings" element={<AccountSettingsPage />} />*/}
        {/*<Route path="/auth" element={<AuthPage />} />*/}
        {/*<Route path="/users/:userId" element={<Navigate to="/" />} />*/}
        {/*<Route path="/quit" element={<QuitPage />} />*/}
        {/*<Route path="/quit/success" element={<QuitSuccessPage />} />*/}
      </Routes>
      <Toast />
    </>
  );
}

export default App;
