import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';

const PrivacyNinthSection = () => {
  return (
    <PrivacySection title={'9. 개인정보의 안전성 확보조치'}>
      <PrivacyParagraph>
        산타파이브는 이용자의 개인정보를 처리함에 있어 안전성을 확보하기 위하여
        다음과 같은 대책을 강구하고 있습니다.
        <br />
        • 이용자의 비밀번호와 같은 중요정보는 암호화하여 저장 및 관리되고
        있으며, 개인정보의 확인 및 변경은 비밀번호를 알고 있는 본인에 의해서만
        가능합니다.
        <br />
        • 암호화 통신을 통하여 네트워크상에서 개인정보를 안전하게 송수신하고
        있습니다.
        <br />
        • 개인정보를 처리하는 인원을 최소한으로 제한하고 있습니다.
        <br />
        단, 산타파이브가 개인정보보호 의무를 다하였음에도 불구하고 이용자 본인의
        부주의나 산타파이브가 관리하지 않는 영역에서의 사고 등 산타파이브의
        귀책에 기인하지 않은 손해에 대해서는 산타파이브가 책임을 지지 않습니다.
      </PrivacyParagraph>
    </PrivacySection>
  );
};

export default PrivacyNinthSection;
