import { textPrimary } from '../../App/styles/colorToken';
import { ReactNode } from 'react';
import styled from '@emotion/styled';

interface PrivacySectionProps {
  className?: string;
  children: ReactNode;
}

const PrivacyParagraph = (props: PrivacySectionProps) => {
  return <Content className={props.className}>{props.children}</Content>;
};

const Content = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;

  color: ${textPrimary};

  margin-bottom: 0.625rem;
`;

export default PrivacyParagraph;
