import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';
import CounselingTable from '../tables/CounselingTable';

const PrivacyEleventhSection = () => {
  return (
    <PrivacySection title={'11. 권익 침해에 대한 구제방법'}>
      <PrivacyParagraph>
        기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의
        가능합니다.
      </PrivacyParagraph>
      <CounselingTable />
    </PrivacySection>
  );
};

export default PrivacyEleventhSection;
