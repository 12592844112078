import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';

const PrivacyFifthSection = () => {
  return (
    <PrivacySection title={'5. 개인정보의 파기'}>
      <PrivacyParagraph>
        산타파이브는 원칙적으로 이용자의 회원 탈퇴(이용계약 해지)시 해당
        이용자의 개인정보를 재생이 불가능한 방법으로 지체없이 파기합니다. 단,
        이용자로부터 별도 동의를 얻은 경우나 관련법령에서 일정기간 보관의무를
        부과하는 경우에는 해당 기간동안 이를 보관합니다.
        <br />
        이용자로부터 개인정보의 보관기관에 대해 회원가입시 동의를 얻는 경우는
        다음과 같습니다.
        <br />• 회원의 이용자 고유 식별자 : 탈퇴일로부터 30일간 보관 (민원 등
        처리 목적)
      </PrivacyParagraph>
    </PrivacySection>
  );
};

export default PrivacyFifthSection;
