import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';

const PrivacySeventhSection = () => {
  return (
    <PrivacySection title={'7. 개인정보 자동수집 장치의 설치, 운영'}>
      <PrivacyParagraph>
        서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이 자동으로
        생성되어 수집될 수 있습니다.
        <br />• IP Address, 방문 일시, 서비스 이용 기록: 부정 이용 방지, 비인가
        사용 방지 등
      </PrivacyParagraph>
    </PrivacySection>
  );
};

export default PrivacySeventhSection;
