import PrivacySection from '../PrivacySection';
import PrivacyParagraph from '../PrivacyParagraph';

const PrivacyTenthSection = () => {
  return (
    <PrivacySection title={'10. 개인정보 보호책임자 안내'}>
      <PrivacyParagraph>
        산타파이브는 개인정보에 대한 의견수렴 및 불만처리, 열람청구에의 대응
        등을 위하여 다음과 같이 개인정보보호책임자를 지정하고 있습니다. 이용자는
        아래의 연락처로 서비스를 이용하며 발생하는 개인정보에 관한 제반사항에
        대한 문의, 개인정보 열람청구 등을 할 수 있고, 산타파이브는 그에 대해
        신속하게 답변 및 처리합니다.
        <br />
        • 이름: 조단원
        <br />
        • 직위: 대표
        <br />• 이메일: santafive.cs@gmail.com
      </PrivacyParagraph>
    </PrivacySection>
  );
};

export default PrivacyTenthSection;
