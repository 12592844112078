import PrivacyParagraph from '../PrivacyParagraph';
import PrivacySection from '../PrivacySection';

const PrivacyThirdSection = () => {
  return (
    <PrivacySection title={'3. 개인정보의 제3자 제공'}>
      <PrivacyParagraph>
        산타파이브는 원칙적으로 이용자 동의 없이 개인정보를 제3자에게 제공하지
        않습니다. 다만, 아래의 경우에는 예외로 합니다.
        <br />
        • 개인정보보호법 등 관계 법령이 정하는 경우
        <br />
        • 수사 기관의 요청이 있는 경우
        <br />
        • 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한
        경우
        <br />
        • 통계작성, 학술연구, 시장조사를 위하여 특정개인을 식별할 수 없는 형태로
        가공하여 제공하는 경우
        <br />• 보유 및 이용기간: 서비스 제공 기간 (단, 관계법령에 정해진 규정에
        따른 해당 보유기간 동안 보관하며, 목적 달성시 즉시 파기)
      </PrivacyParagraph>
    </PrivacySection>
  );
};

export default PrivacyThirdSection;
