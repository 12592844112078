import styled from '@emotion/styled';
import { Body2, H2, H4 } from '../../App/components/Text';
import {
  background,
  black,
  blackSecondary,
  primaryRedLight,
} from '../../App/styles/colorToken';
import ImageKeyAsset from '../../App/assets/image-key.png';
import ImageLockAsset from '../../App/assets/image-lock.png';
import ImageTreeAsset from '../../App/assets/image-tree.png';

const UpdatesSection = () => {
  return (
    <Container>
      <SectionTitle>
        올해의 <Highlight>내 트리를 꾸며줘!</Highlight>는 <BR />
        이렇게 바뀌어요
      </SectionTitle>
      <InnerContainer>
        <ContentWrapper>
          <ContentImage alt={''} loading="lazy" src={ImageKeyAsset} />
          <ContentTitle id="update-oauth">소셜 로그인</ContentTitle>
          <ContentDescription role="text" aria-details="update-oauth">
            카카오톡, 트위터, 구글 계정으로
            <br />
            트리를 만들 수 있어요
          </ContentDescription>
        </ContentWrapper>
        <ContentWrapper>
          <ContentImage alt={''} loading="lazy" src={ImageLockAsset} />
          <ContentTitle id="update-scope">메시지 공개 / 비공개</ContentTitle>
          <ContentDescription role="text" aria-details="update-scope">
            트리를 만들거나 메시지를 남길 때,
            <br />
            메시지의 공개 여부를 설정할 수 있어요
          </ContentDescription>
        </ContentWrapper>
        <ContentWrapper>
          <ContentImage alt={''} loading="lazy" src={ImageTreeAsset} />
          <ContentTitle id="update-custom">트리 커스텀</ContentTitle>
          <ContentDescription role="text" aria-details="update-custom">
            내 트리 모양과 배경을 직접 커스텀해서
            <br />
            개성있는 트리를 만들 수 있어요
          </ContentDescription>
        </ContentWrapper>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.75rem 0;
  z-index: 1;
  background-color: ${background};
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 57.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 2.5rem;
  }
`;

const SectionTitle = styled(H2)`
  text-align: center;
  color: ${black};
  margin-bottom: 4rem;

  @media (max-width: 800px) {
    margin-bottom: 2rem;
  }
`;

const BR = styled.br`
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;

const Highlight = styled.mark`
  color: ${primaryRedLight};
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentImage = styled.img`
  margin-bottom: 1.5rem;
`;

const ContentTitle = styled(H4)`
  margin-bottom: 0.5rem;
`;

const ContentDescription = styled(Body2)`
  color: ${blackSecondary};
  text-align: center;
`;

export default UpdatesSection;
