import PrivacySection from '../PrivacySection';
import styled from '@emotion/styled';
import { textTertiary } from '../../../App/styles/colorToken';
import PrivacyParagraph from '../PrivacyParagraph';

const ColorMyTreePrivacySection = () => {
  return (
    <PrivacySection title={'내 트리를 꾸며줘! 개인정보처리방침'}>
      <Version>2022. 12. 01. ver</Version>
      <PrivacyParagraph>
        산타파이브는 서비스의 제공에 있어 정보통신망 이용촉진 및 정보보호 등에
        관한 법률, 개인정보보호법 등 관련 개인정보보호 규정을 철저히 준수하며,
        관련법령에 따라 본 개인정보 처리방침을 정하여 이용자의 권익 보호에
        최선을 다하고 있습니다.
      </PrivacyParagraph>
    </PrivacySection>
  );
};

const Version = styled(PrivacyParagraph)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${textTertiary};
`;

export default ColorMyTreePrivacySection;
