export const SANTAFIVE_NOTION_LINK =
  'https://santafive.notion.site/3834450147f8438ba23daa934d7495a9';

export const SANTAFIVE_TWITTER_LINK = 'https://twitter.com/santafive_S2';

export const SANTAFIVE_INSTAGRAM_LINK =
  'https://instagram.com/santafive_official';

export const SANTAFIVE_FACEBOOK_LINK =
  'https://www.facebook.com/profile.php?id=100076166839861';

export const SANTAFIVE_NOTION_DONATION_LINK = `https://santafive.notion.site/201f8d12215b47b0945efb69e0f3fe2a`;
