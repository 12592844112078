import clsx from 'clsx';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import FlipClockDigit from './FlipClockDigit';
import styles from './FlipClockCountdown.module.css';
import {
  Digit,
  FlipClockCountdownProps,
  FlipClockCountdownRenderProps,
  FlipClockCountdownState,
  FlipClockCountdownUnitTimeFormatted,
} from './types';
import { calcTimeDelta, parseTimeDelta } from './utils';

/**
 * https://github.com/sLeeNguyen/react-flip-clock-countdown
 * A 3D animated flip clock countdown component for React.
 */
function FlipClockCountdown(props: FlipClockCountdownProps) {
  const {
    to,
    className,
    style,
    children,
    onComplete,
    onTick,
    showLabels = true,
    showUnits = ['Days', 'Hours', 'Minutes', 'Seconds'],
    labels = ['Days', 'Hours', 'Minutes', 'Seconds'],
    duration,
    ...other
  } = props;
  // we don't immediately construct the initial state here because it might
  // lead to some bugs with server-side rendering and hydration.
  const [state, setState] = React.useState<FlipClockCountdownState>();
  const countdownRef = React.useRef(0);
  function clearTimer() {
    window.clearInterval(countdownRef.current);
  }

  const constructState = useCallback((): FlipClockCountdownState => {
    const timeDelta = calcTimeDelta(to);
    return {
      timeDelta,
      completed: timeDelta.total === 0,
    };
  }, [to]);

  const tick = useCallback(() => {
    const newState = constructState();
    setState(newState);
    onTick?.(newState);
    if (newState.completed) {
      clearTimer();
      onComplete?.();
    }
  }, [constructState, onComplete, onTick]);

  useEffect(() => {
    setState(constructState());
    clearTimer();
    countdownRef.current = window.setInterval(tick, 1000);

    return () => clearTimer();
  }, [constructState, tick, to]);

  const renderProps = useMemo<FlipClockCountdownRenderProps | undefined>(() => {
    if (state === undefined) return undefined;
    const { timeDelta } = state;
    return {
      ...timeDelta,
      formatted: parseTimeDelta(timeDelta),
    };
  }, [state]);

  if (state === undefined || renderProps === undefined) return <></>;

  if (state?.completed) {
    return <>{children}</>;
  }

  const { days, hours, minutes, seconds } = renderProps.formatted;
  const _labels =
    labels.length >= 4 ? labels : ['Days', 'Hours', 'Minutes', 'Seconds'];

  const filteredUnits = showUnits
    .map((unit) => {
      switch (unit) {
        case 'Days':
          return days;
        case 'Hours':
          return hours;
        case 'Minutes':
          return minutes;
        case 'Seconds':
          return seconds;
        default:
          return undefined;
      }
    })
    .filter(Boolean) as FlipClockCountdownUnitTimeFormatted[];

  const makeAriaLabel = (digit: Digit[], index: number) => {
    const value = digit.join('');
    const unitText = ['일', '시간', '분', '초'];
    const unit = unitText[index];

    return `${value}${unit}`;
  };

  return (
    <div
      {...other}
      className={clsx(
        styles.fcc__container,
        { [styles.fcc__label_show]: showLabels },
        className
      )}
      aria-label={`남은 시간 ${filteredUnits.map((item, idx) =>
        makeAriaLabel(item.current, idx)
      )}`}
      /* eslint-disable-next-line jsx-a11y/aria-role */
      role={'text'}
      data-testid="fcc-container"
    >
      {filteredUnits.map((item, idx) => {
        return (
          <Fragment key={`digit-block-${idx}`}>
            <div className={styles.fcc__digit_block_container}>
              {showLabels && (
                <div className={styles.fcc__digit_block_label}>
                  {_labels[idx]}
                </div>
              )}
              {item.current.map((cItem, cIdx) => (
                <FlipClockDigit
                  key={cIdx}
                  current={cItem}
                  next={item.next[cIdx]}
                />
              ))}
            </div>
            {Boolean(filteredUnits[idx + 1]) ? (
              <div className={styles.fcc__colon}></div>
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
}

export default FlipClockCountdown;
