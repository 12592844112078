import {
  Digit,
  FlipClockCountdownTimeDelta,
  FlipClockCountdownTimeDeltaFormatted,
} from './types';

export const defaultTimeDelta = {
  total: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

const getKoreaNow = () => {
  const now = new Date(); // 현재 시간
  const utcNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000; // 현재 시간을 utc로 변환
  const koreaTimeDiff = 9 * 60 * 60 * 1000; // 한국 시간은 UTC보다 9시간 빠름
  return new Date(utcNow + koreaTimeDiff); // utc로 변환된 값을 한국 시간으로 변환시키기 위해 9시간(밀리세컨드)를 더함
};

export function calcTimeDelta(
  target: Date | number | string
): FlipClockCountdownTimeDelta {
  const date = new Date(target);
  if (isNaN(date.getTime())) {
    throw Error('Invalid date');
  }

  const koreaNow = getKoreaNow();
  let timeLeft = Math.round((date.getTime() - koreaNow.getTime()) / 1000); // convert to seconds
  if (timeLeft < 0) timeLeft = 0;

  return {
    total: timeLeft,
    days: Math.floor(timeLeft / (24 * 60 * 60)),
    hours: Math.floor((timeLeft / 3600) % 24),
    minutes: Math.floor((timeLeft / 60) % 60),
    seconds: Math.floor(timeLeft % 60),
  };
}

export function pad(n: number): Digit[] {
  return ('0'.repeat(Math.max(0, 2 - String(n).length)) + String(n)).split('');
}

export function parseTimeDelta(
  timeDelta: FlipClockCountdownTimeDelta
): FlipClockCountdownTimeDeltaFormatted {
  const nextTimeDelta = calcTimeDelta(
    new Date().getTime() + (timeDelta.total - 1) * 1000
  );

  return {
    days: {
      current: pad(timeDelta.days),
      next: pad(nextTimeDelta.days),
    },
    hours: {
      current: pad(timeDelta.hours),
      next: pad(nextTimeDelta.hours),
    },
    minutes: {
      current: pad(timeDelta.minutes),
      next: pad(nextTimeDelta.minutes),
    },
    seconds: {
      current: pad(timeDelta.seconds),
      next: pad(nextTimeDelta.seconds),
    },
  };
}

export function convertToPx(n?: string | number): string | undefined {
  if (n === undefined) return undefined;
  if (typeof n === 'string') return n;
  return `${n}px`;
}
