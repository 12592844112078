import styled from '@emotion/styled';
import { Body1, ButtonText, H2 } from '../../App/components/Text';
import {
  primaryRedDark,
  primaryRedLight,
  white,
  whiteSecondary,
} from '../../App/styles/colorToken';
import { SANTAFIVE_NOTION_DONATION_LINK } from '../../App/constants/links';
import imageDonation1xWebp from '../../App/assets/landing/image-donation-1x.webp';
import imageDonation1xAsset from '../../App/assets/landing/image-donation-1x.png';
import imageDonation2xWebp from '../../App/assets/landing/image-donation-2x.webp';
import imageDonation2xAsset from '../../App/assets/landing/image-donation-2x.png';
import { logClickLinkEvent } from '../../App/utils/analytics';

const DonationSection = () => {
  return (
    <Container id="donation-section">
      <InnerContainer>
        <ContentWrapper>
          <SectionTitle id="donation">
            작년 크리스마스에 받은 사랑, <br />
            <Highlight>기부</Highlight>로 돌려드렸어요
          </SectionTitle>
          <SectionDescription aria-details="donation">
            8개 기관에 약 2000만원을 기부했어요
          </SectionDescription>
          <ButtonWrapper>
            <SantafiveDonationNotionLink
              href={SANTAFIVE_NOTION_DONATION_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                logClickLinkEvent({ event_label: 'click_donation_document' })
              }
            >
              <ButtonText>기부 이야기 자세히 보기</ButtonText>
            </SantafiveDonationNotionLink>
          </ButtonWrapper>
        </ContentWrapper>
        <picture>
          <source
            type="image/webp"
            media="(max-width:800px)"
            srcSet={imageDonation1xWebp}
          />
          <source media="(max-width:800px)" srcSet={imageDonation1xAsset} />
          <source type="image/webp" srcSet={imageDonation2xWebp} />
          <DonationImage alt={''} loading="lazy" src={imageDonation2xAsset} />
        </picture>
      </InnerContainer>
    </Container>
  );
};

export default DonationSection;

const Container = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.75rem 0;
  z-index: 1;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 57.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

const ContentWrapper = styled.div``;

const SectionTitle = styled(H2)`
  color: ${white};
  margin-bottom: 1.5rem;

  @media (max-width: 800px) {
    margin-bottom: 1rem;
  }
`;

const Highlight = styled.mark`
  color: ${primaryRedDark};
`;

const SectionDescription = styled(Body1)`
  color: ${whiteSecondary};
`;

const ButtonWrapper = styled.div`
  display: flex;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const SantafiveDonationNotionLink = styled.a`
  margin-top: 1.5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  color: ${white};

  background: ${primaryRedLight};
  border-radius: 0.625rem;
`;

const DonationImage = styled.img`
  width: 22rem;

  @media (max-width: 800px) {
    width: 13rem;
    margin-bottom: 1rem;
  }
`;
